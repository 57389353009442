import './App.css';

import { Fragment } from 'react';

import Teaser from './pages/Teaser';

import Booking from './pages/Booking';

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from "react-router-dom";

function App() {

  return (
    <Router>
      <Routes>
            <Route path="/" element={<Teaser/>}/>
            {/* <Teaser/> */}
            {/* <Route path="/booking" element={<Booking/>}/> */}
      </Routes>

    </Router>
  );
}

export default App;
