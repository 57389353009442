
import React, {Component, Fragment} from 'react';

// @ts-ignore;
// import gsap, {Power1 } from 'gsap';

import logo_small from '../logo_small.svg';

export default class Booking extends Component {

    checkElement(selector, callback) {
        const observer = new MutationObserver(function(mutations) {

            // console.log("CHANGES");

            mutations.forEach(function(mutation) {

                // console.log(mutation);

                if (mutation.addedNodes) {
                const element = Array.from(mutation.addedNodes).find(function(node) {
                    return node.matches && node.matches(selector);
                });
        
                if (element) {
                    observer.disconnect();
                    callback(element);
                }
                }
            });
        });
      
        observer.observe(document.documentElement, {
          childList: true,
          subtree: true
        });
    }

    componentDidMount () {

        const booking = document.getElementById("apartmentIframeAll");
        booking.style.display = "block";
    }

    render (){
        return (
            <Fragment>
                <div>
                    <div id="fern-video"></div>
                </div>
                <div className="fern-small">
                    <img src={logo_small} className="thefern-logo-small" alt="fern dom mazurski" />
                </div>
                <div className="fern-footer"><a href="https://www.instagram.com/fern_dom_mazurski/" className="fern-link" target="_blank" rel="noreferrer">instagram</a><a href="/booking" className="fern-link" target="_blank" rel="noreferrer">reservations</a><a href="mailto:contact@thefern.pl" className="fern-link" target="_blank" rel="noreferrer">contact</a></div>
            </Fragment>);
    };
};
