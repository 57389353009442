
import React, {Component, Fragment} from 'react';

// @ts-ignore;
import gsap from 'gsap';

import logo from '../logo.svg';

export default class Teaser extends Component {

    componentDidMount () {

        gsap.set(".cursor-bg", {xPercent: -50, yPercent: -50});
        gsap.set(".cursor-bg2", {xPercent: -50, yPercent: -50});

        var ball = document.querySelector(".cursor-bg");
        var ball2 = document.querySelector(".cursor-bg2");

        var pos = { x: window.innerWidth / 2, y: window.innerHeight / 2 };
        var pos2 = { x: window.innerWidth / 2, y: window.innerHeight / 2 };
        var mouse = { x: pos.x, y: pos.y };
        var speed = 0.3;

        var speed2 = 0.6;

        var speedS1 = 0;

        var speedS2 = 0;

        var fpms = 60 / 1000;

        var xSet = gsap.quickSetter(ball, "x", "px");
        var ySet = gsap.quickSetter(ball, "y", "px");

        var xSet2 = gsap.quickSetter(ball2, "x", "px");
        var ySet2 = gsap.quickSetter(ball2, "y", "px");

        var scaleXSet = gsap.quickSetter(ball, "scaleX");
        var scaleYSet = gsap.quickSetter(ball, "scaleY");

        var scaleXSet2 = gsap.quickSetter(ball2, "scaleX");
        var scaleYSet2 = gsap.quickSetter(ball2, "scaleY");


        window.addEventListener("mousemove", e => {    
        mouse.x = e.x;
        mouse.y = e.y;  
        });

        gsap.ticker.add((time, deltaTime) => {
  
            var delta = deltaTime * fpms;
            var dt = 1.0 - Math.pow(1.0 - speed, delta); 
            
            pos.x += (mouse.x - pos.x) * dt;
            pos.y += (mouse.y - pos.y) * dt;

            var dt2 = 1.0 - Math.pow(1.0 - speed2, delta); 
            
            pos2.x += (mouse.x - pos2.x) * dt2;
            pos2.y += (mouse.y - pos2.y) * dt2;

            xSet(pos.x);
            ySet(pos.y);

            xSet2(pos2.x);
            ySet2(pos2.y);
            
            var speedS11 = Math.abs(mouse.x - pos.x)/100;

            var speedS22 = Math.abs(mouse.x - pos2.x)/100;


            speedS1 += (speedS11 - speedS1) / 10;
            speedS2 += (speedS22 - speedS2) / 10;

            scaleXSet(2 + speedS1);
            scaleYSet(2 + speedS1);

            scaleXSet2(2 + speedS2);
            scaleYSet2(2 + speedS2);

            //scaleXSet2(2 + Math.abs(mouse.x - pos2.x)/100);
            //scaleYSet2(2 + Math.abs(mouse.x - pos2.x)/100);
          });
    }

    render (){
        return (
            <Fragment>
            <div>
              <div id="fern-video"></div>
              {/* <video autoPlay muted loop playsInline id="fern-video"> */}
                {/* <source src={fern} type="video/mp4"/> */}
              {/* </video> */}
              {/* <div className="hide-bg"></div> */}
            </div>
            <div className="cursor-bg2"></div>
            <div className="fern">
              <img src={logo} className="thefern-logo" alt="thefern" />
            </div>
            <div className="fern-footer">
              <a href="https://www.instagram.com/fern_dom_mazurski/" className="fern-link" target="_blank" rel="external">instagram</a>
              {/* <a href="/booking" className="fern-link" target="_blank" rel="noreferrer">reservations</a> */}
              <a href="mailto:booking@thefern.pl" className="fern-link" target="_blank" rel="noreferrer">booking</a></div>
            <div className="cursor-bg"></div>
            
          </Fragment>);
    };
};
